<template>
    <modal ref="modalDetallePago" titulo="Detalle del pago" no-aceptar no-cancelar :tamano="`${tipo == 1 ? '' : 'modal-lg'}`">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col px-0">
                <div class="row mx-0">
                    <div class="col">
                        <div class="row mx-0">
                            <img :src="url_image" width="33" height="18" class="obj-cover" />
                            <div class="col-auto text-general f-600">
                                {{ separadorNumero(transferencia.valor) }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2">
                            <img :src="transferencia.leechero.foto" width="36" height="36" class="obj-cover rounded-circle" />
                            <div class="col pl-2">
                                <p class="text-general f-17 f-600">Leechero</p>
                                <p class="text-general f-17">{{ transferencia.leechero.nombre }}</p>
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 text-general f-15">
                            <div class="col pl-0 f-600">
                                Referencia
                            </div>
                            <div class="col">
                                {{ transferencia.id }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 text-general f-15">
                            <div class="col pl-0 f-600">
                                Fecha y hora del pago
                            </div>
                            <div class="col">
                                {{ formatearFecha(transferencia.created_at, 'D MMM Y - HH:mmA') }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 text-general f-15">
                            <div class="col pl-0 f-600">
                                Medio de pago
                            </div>
                            <div class="col">
                                {{ tipo == 1 ? 'Efectivo' : 'Transferencia' }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 text-general f-15">
                            <div class="col pl-0 f-600">
                                Caja
                            </div>
                            <div class="col">
                                {{ transferencia.caja }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 text-general f-15">
                            <div class="col pl-0 f-600">
                                Observación
                            </div>
                            <div class="col">
                                {{ transferencia.comentario || 'Sin observación.' }}
                            </div>
                        </div>
                        <div v-if="transferencia.transferencia_estado == 4 && tipo == 2 " class="row mx-0 mt-4">
                            <div class="rectificacion d-middle-center px-3">
                                <i class="icon-alert-triangle f-18 text-warning" />
                                <span class="ml-2">
                                    Verificación en espera
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="tipo == 2" class="col-6 text-center">
                        <p class="text-general f-14 f-600">Imagen del recibo</p>
                        <inner-image-zoom v-if="transferencia.imagen != null" :src="transferencia.imagen" :zoom-src="`${transferencia.imagen}`" class="obj-cover border br-4" style="width:260px;height:170px;" />
                        <img v-else :src="url_persona" class="obj-cover border br-4" style="width:260px;height:170px;" />
                        <template v-if="transferencia.transferencia_estado == 1 || transferencia.transferencia_estado == 4">
                            <div v-if="$can('boton_flujo_dinero_cuentas_verificar_pago')" class="bg-general br-8 mx-4 px-2 text-white mt-3 d-middle-center cr-pointer" style="height:32px;" @click="verificar">
                                Verificar transferencia
                            </div>
                            <div v-if="transferencia.transferencia_estado == 1 && $can('boton_flujo_dinero_cuentas_en_espera_pago')" class="bg-white border br-8 mx-4 mt-3 text-general d-middle-center cr-pointer" style="height:32px;" @click="esperar">
                                Dejar en espera
                            </div>
                            <div v-if="transferencia.transferencia_estado == 4 && $can('boton_flujo_dinero_cuentas_descartar_pago')" class="bg-white border br-8 mx-4 mt-3 text-general d-middle-center cr-pointer" style="height:32px;" @click="descartar">
                                Descartar Pago
                            </div>
                        </template>
                    </div>
                </div>
                <div v-if="transferencia.transferencia_estado != 1 && tipo == 2" class="row mx-0 mt-4">
                    <div class="col-6">
                        <div class="row mx-0">
                            <img :src="transferencia.encargado.foto" width="36" height="36" class="obj-cover rounded-circle" />
                            <div class="col pl-2">
                                <p class="text-general f-17 f-600">{{ tituloTransferencia }}</p>
                                <p class="text-general f-17">{{ transferencia.encargado.nombre }}</p>
                            </div>
                        </div>
                        <div class="row mx-0 text-general f-15 mt-3">
                            <div class="col-auto px-0 f-600">
                                Fecha y hora
                            </div>
                            <div class="col text-right">
                                {{ formatearFecha(transferencia.updated_at,'D MMM Y - HH:mmA') }}
                            </div>
                        </div>
                    </div>
                    <div v-if="transferencia.transferencia_estado > 2" class="col-6 f-15 text-general">
                        <p>
                            <b class="mr-2">Justificación de descarte</b>
                        </p>
                        <p class="f-15 mt-2">
                            <span>{{ transferencia.transferencia_comentario }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            url_image: '/img/icons/pago_efectivo.svg',
            url_persona: 'https://cdn.iahorro.com/storage/blog/2019/07/metodos_de_pago_alternativos_a_las_tarjetas_de_credito/metodos_de_pago_alternativos_a_las_tarjetas_de_credito.JPG',
            transferencia: {
                comentario: null,
                created_at: null,
                created_by: null,
                encargado: {
                    nombre: '',
                    foto: '',
                },
                estado: null,
                id: null,
                id_caja: null,
                id_leechero: null,
                id_pedido: null,
                id_pedido_admin: null,
                imagen: null,
                leechero: {
                    nombre: '',
                    foto: '',
                },
                tipo_efectivo: null,
                transferencia_comentario: null,
                transferencia_estado: null,
                updated_at: null,
                updated_by: null,
                valor: null,
            },
            tipo: null
        }
    },
    computed: {
        tituloTransferencia(){
            switch (this.transferencia.transferencia_estado){
            case 2:
                return 'Verificó Pago'
            case 3:
                return 'Descartó Pago'
            case 4:
                return 'Dejó en espera'
            default:
                return ''
            }
        }
    },
    methods: {
        toggle(transferencia, tipo){
            this.tipo = tipo
            this.transferencia  = transferencia
            this.$refs.modalDetallePago.toggle();
        },
        verificar(){
            this.$emit('verificar', this.transferencia)
            this.$refs.modalDetallePago.toggle();
        },
        esperar(){
            this.$emit('esperar', this.transferencia)
            this.$refs.modalDetallePago.toggle();
        },
        descartar(){
            this.$emit('descartar', this.transferencia)
            this.$refs.modalDetallePago.toggle();
        },
    }
}
</script>
<style lang="scss" scoped>
.rectificacion{
    height: 32px;
    border: 1px solid #FF8B32;
    background-color: #FF8B320D;
    color: var(--text-general);
    border-radius: 8px;
    font-weight: 600;
}
</style>
